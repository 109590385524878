import { render, staticRenderFns } from "./DistributeursListeMainVue.vue?vue&type=template&id=21442811&scoped=true&"
import script from "./DistributeursListeMainVue.vue?vue&type=script&lang=js&"
export * from "./DistributeursListeMainVue.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21442811",
  null
  
)

export default component.exports