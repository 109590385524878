<template>
  <div>
    <b-card>
      <b-row>

        <!-- User Info: Left col -->
        <b-col
          cols="6"
          md="4"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="commercialData.avatar"
              :text="avatarText(`${commercialData.prenom} ${commercialData.nom}`)"
              :variant="`light-${resolveUserRoleVariant(commercialData.role)}`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ commercialData.first_name }} {{ commercialData.last_name }}
                </h4>
                <span class="card-text">{{ commercialData.telephone }}</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-row>
                  <b-col md="12" />

                </b-row>

              </div>
            </div>
          </div>

          <!-- User Stats -->
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="8"
          md="6"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageResponsable"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Responsable :</span>
              </th>
              <td class="pb-50">
                {{ commercialData.responsable }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageDistributeurs"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Nombre de Distributeurs : </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ commercialData.nbr_distributeur }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imageTourners"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Nombre de Visites : </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ commercialData.nbr_visites_pdv }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img
                  width="30px"
                  :src="imagePDV"
                  alt="Description de l'image"
                >
                <span class="font-weight-bold ml-1">Nombre de PDV : </span>
              </th>
              <td class="pb-50">
                {{ commercialData.nbr_pdv }}
              </td>
            </tr>
          </table>
        </b-col>
        <!--        <b-col-->
        <!--          cols="6"-->
        <!--          md="4"-->
        <!--        >-->
        <!--          <vue-apex-charts-->
        <!--            height="120"-->
        <!--            :options="earningsChart.chartOptions"-->
        <!--            :series="[53, 47]"-->
        <!--          />-->
        <!--          <h4 class="mb-0 text-center">-->
        <!--            La Tournée du jour-->
        <!--          </h4>-->
        <!--        </b-col>-->
      </b-row>
    </b-card>
    <b-card>

      <b-tabs
        pills
      >

        <b-tab active>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imagePDV"
                alt="Description de l'image"
              >
            </span>

            <span class="d-none d-sm-inline ml-1">PDV</span>
          </template>
          <PDVListeMainVue :rows="[]" />
        </b-tab>

        <b-tab>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imageDistributeurs"
                alt="Description de l'image"
              >
            </span>
            <span class="d-none d-sm-inline ml-1">Distributeurs</span>
          </template>
          <DistributeursListeMainVue :rows="[]" />
        </b-tab>

        <b-tab>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imageTourners"
                alt="Description de l'image"
              >
            </span>
            <span class="d-none d-sm-inline ml-1">Visites - PDV</span>
          </template>
          <VisitesPDV />
        </b-tab>
        <b-tab>
          <template #title>
            <span class="tab-image-icon">
              <img
                width="20px"
                :src="imageTourners"
                alt="Description de l'image"
              >
            </span>
            <span class="d-none d-sm-inline ml-1">Taux de disponibilités</span>
          </template>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Date de début"
                label-for="nom-input"
              >

                <b-form-input
                  id="nom-input"
                  ref="date1"
                  v-model="dateDebutTaux"
                  type="date"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date de fin"
                label-for="nom-input"
              >

                <b-form-input
                  id="nom-input"
                  ref="date2"
                  v-model="dateFinTaux"
                  type="date"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <div style="text-align: center">
                <b-button
                  id="toggle-btn"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.distributeur-modal-prevent-closing
                  variant="outline-primary"
                  class="btn_export ml-2"
                  @click="getTauxDisponibilite"
                >
                  <feather-icon icon="SearchIcon" />
                  Filtrer
                </b-button>
              </div>

            </b-col>
          </b-row>
          <hr>
          <h2> Taux de disponibilité des produits Afridia</h2>
          <b-row>
            <b-col md="3">
              <!-- input search -->
              <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="mr-1">Recherche
                    </label>
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Recherche"
                      type="text"
                      class="d-inline-block"
                    />
                  </div>
                </b-form-group>
                <!-- primary -->
                <b-dropdown
                  id="dropdown-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  text="Exporter"
                  variant="primary"
                  class="ml-2 btn_export"
                >
                  <b-dropdown-item @click="exportCSVPA">
                    CSV
                  </b-dropdown-item>
                  <b-dropdown-item @click="exportExcelPA">
                    MS-Excel
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <vue-good-table
                id="list"
                ref="myTable"
                :columns="columns"
                :rows="tauxPA"
                :rtl="direction"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm }"
                :pagination-options="{
                  enabled: true,
                  perPage:pageLength
                }"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >

                  <!-- Column: Status -->
                  <span v-if="props.column.field === 'status'">
                    <b-badge :variant="statusVariant(props.row.status)">
                      {{ props.row.status }}
                    </b-badge>
                  </span>

                  <!-- Column: Action -->
                  <span v-else-if="props.column.field === 'action'">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      :to="{ name: 'tourners-visite-details', params: { id: props.row.id } }"
                    >
                      Voir
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
                      class="ml-1"
                      @click="handleDelete(props.row.id)"
                    >
                      Supprimer
                    </b-button>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap">
                        Affichage de 1 à
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['3','5','10']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap "> sur {{ props.total }} élements </span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
            <b-col md="9">
              <SimpleColumnChartV
                v-if="tauxPA.length"
                :chart-data="tauxPA"
                :category-name="'produit'"
                :series-name="'taux'"
                :value-y-name="'taux'"
              />
            </b-col>
          </b-row>
          <hr>
          <h2>Taux de disponibilité des produits Concurrent</h2>
          <b-row>
            <b-col md="3">
              <!-- input search -->
              <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="mr-1">Recherche
                    </label>
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Recherche"
                      type="text"
                      class="d-inline-block"
                    />
                  </div>
                </b-form-group>
                <!-- primary -->
                <b-dropdown
                  id="dropdown-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  text="Exporter"
                  variant="primary"
                  class="ml-2 btn_export"
                >
                  <b-dropdown-item @click="exportCSVPC">
                    CSV
                  </b-dropdown-item>
                  <b-dropdown-item @click="exportExcelPC">
                    MS-Excel
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <vue-good-table
                id="list"
                ref="myTable"
                :columns="columns"
                :rows="tauxPC"
                :rtl="direction"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm }"
                :pagination-options="{
                  enabled: true,
                  perPage:pageLength
                }"
              >
                <template
                  slot="table-row"
                  slot-scope="props"
                >

                  <!-- Column: Status -->
                  <span v-if="props.column.field === 'status'">
                    <b-badge :variant="statusVariant(props.row.status)">
                      {{ props.row.status }}
                    </b-badge>
                  </span>

                  <!-- Column: Action -->
                  <span v-else-if="props.column.field === 'action'" />

                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap">
                        Affichage de 1 à
                      </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['3','5','10']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                      />
                      <span class="text-nowrap "> sur {{ props.total }} élements </span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                      >
                        <template #prev-text>
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </template>
                        <template #next-text>
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
            <b-col md="9">
              <SimpleColumnChartV
                v-if="tauxPC.length"
                :id="'chartConcurrent'"
                :chart-data="tauxPC"
                :category-name="'produit'"
                :series-name="'taux'"
                :value-y-name="'taux'"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>

</template>

<script>
import {
  BAvatar, BBadge,
  BButton,
  BCard,
  BCol, BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
  BTab,
  BTabs,
  VBModal,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useUsersList from '@/views/apps/user/users-list/useUsersList'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { API_URL } from '@/helpers/global-scops'
import TournerListeMainVue from '@/views/afridia/commerciaux/details/TournerListeMainVue.vue'
import DistributeursListeMainVue from '@/views/afridia/commerciaux/details/DistributeursListeMainVue.vue'
import PDVListeMainVue from '@/views/afridia/commerciaux/details/PDVListeMainVue.vue'
import Ripple from 'vue-ripple-directive'
import htmlToPaper from 'vue-html-to-paper'
import VisiteListeMainVue from '@/views/afridia/tourners/details/VisiteListeMainVue.vue'
import { getCurrentDate, getFirstDayOfCurrentMonth } from '@/helpers/methodes'
import store from '@/store'
import VueGoodTable from 'vue-good-table/src/components/Table.vue'
import SimpleColumnChartV from '@/views/m-components/charts/SimpleColumnChartV.vue'
import VisitesPDV from '@/views/afridia/commerciaux/details/VisitesPDV.vue'

const $earningsStrokeColor2 = 'rgb(1,132,38)'
const $earningsStrokeColor3 = 'rgba(40,199,111,0.24)'

export default {
  name: 'MainVue',
  components: {
    VisitesPDV,
    SimpleColumnChartV,
    VueGoodTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BPagination,
    BFormGroup,
    BFormInput,
    VisiteListeMainVue,
    PDVListeMainVue,
    DistributeursListeMainVue,
    TournerListeMainVue,
    BTabs,
    BTab,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BCard,
    VueApexCharts,
  },
  directives: {
    Ripple,
    htmlToPaper,
    'b-modal': VBModal,
  },
  data() {
    return {
      pageLength: 10,
      searchTerm: '',
      columns: [
        {
          label: 'Produit',
          field: 'produit',
          filterable: true,
        },
        {
          label: 'Taux',
          field: 'taux',
          type: 'number',
          filterable: true,
        },
      ],
      dateDebutTaux: '',
      dateFinTaux: '',
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: true,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          comparedResult: [47, 53],
          labels: ['Effectuées', 'Restants'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Visites',
                    formatter() {
                      return '53%'
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
      commercialData: {
        id: 1,
        nom: 'Ouedraogo',
        prenom: 'Fatimata',
        telephone: '01 23 45 67',
        responsable: 'Directeur régional',
        canal: 'Canal moderne',
        nbr_distributeurs: 10,
        nbr_tourners: 15,
        nbr_pdv: 50,
        status: 'Actif',
        action: '',
      },
      // eslint-disable-next-line global-require
      imageDistributeurs: require('@/assets/images/icons/box.png'),
      // eslint-disable-next-line global-require
      imageResponsable: require('@/assets/images/icons/user-gear.png'),
      // eslint-disable-next-line global-require
      imageTourners: require('@/assets/images/icons/tourners.png'),
      // eslint-disable-next-line global-require
      imagePDV: require('@/assets/images/icons/store.png'),
      userData: {},
      tauxPA: [],
      tauxPC: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
  mounted() {
    this.dateDebutTaux = getFirstDayOfCurrentMonth()
    this.dateFinTaux = getCurrentDate()
    this.getTauxDisponibilite()
  },
  created() {
    this.getCommercial()
  },
  methods: {
    exportExcelPA() {
    },
    exportExcelPC() {
    },
    exportCSVPA() {
      const header = this.columns.map(column => column.label)
      const data = this.tauxPA.map(row => this.columns.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(',')).join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${this.filename}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    exportCSVPC() {
      const header = this.columns.map(column => column.label)
      const data = this.tauxPC.map(row => this.columns.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(',')).join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${this.filename}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    getCommercial() {
      this.$http.get(`${API_URL}commercials/${this.$route.params.id}/`)
        .then(response => {
          this.commercialData = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getTauxDisponibilite() {
      this.$http.get(`${API_URL}get-commercial-visites-pa-rate/?commercial_id=${this.$route.params.id}&start_date=${this.dateDebutTaux}&end_date=${this.dateFinTaux}`)
        .then(response => {
          this.tauxPA = response.data
        })
        .catch(error => {
          console.log(error)
        })

      this.$http.get(`${API_URL}get-commercial-visites-pc-rate/?commercial_id=${this.$route.params.id}&start_date=${this.dateDebutTaux}&end_date=${this.dateFinTaux}`)
        .then(response => {
          this.tauxPC = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style scoped>

</style>
